import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "button.component.html",
  styleUrl: "button.component.scss",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class ButtonComponent {
  @Input() variant: string = "primary"; //primary,secondary,tertiary
  @Input() disabled: boolean = false;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  click() {
    this.clicked.emit(true);
  }
}
