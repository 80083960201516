import { ChangeDetectorRef, Component, NgZone, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";
import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiService } from "../../../../../../../../core/providers/api.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { ButtonComponent } from "../../../../../shared-ui/base/core/buttons/button/button.component";
import { ButtonBackComponent } from "../../../../../shared-ui/base/core/buttons/button-back/button-back.component";
import { CloseButtonComponent } from "../../../../../shared-ui/base/core/buttons/button-close/close-button.component";
import { ButtonSvgComponent } from "../../../../../shared-ui/base/core/buttons/button-svg-square/button-svg.component";
import { DialogBottomBorderOverlapComponent } from "../../../../../shared-ui/base/core/dialog/dialog-bottom-border-overlap/dialog-bottom-border-overlap.component";
import { DialogEmptyPageComponent } from "../../../../../shared-ui/base/core/dialog/dialog-empty-page/dialog-empty-page.component";
import { DialogTitleComponent } from "../../../../../shared-ui/base/core/dialog/dialog-title/dialog-title.component";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { SharedUiMobileModule } from "../../../../../shared-ui/mobile/shared-ui-mobile.module";
import { ApiBusinessQuestionService } from "../../../../api/core/services/api-business-question.service";
import { EVENT_DIALOGS_NAMES_DAILY_QUESTIONS } from "../../../../consts/event-dialogs/event-names.const";
import { Question, QuestionAnswer } from "../../../../interfaces/core/question.interface";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.scss"],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    SharedModule,
    SharedUiMobileModule,
    CloseButtonComponent,
    DialogEmptyPageComponent,
    DialogTitleComponent,
    ButtonBackComponent,
    OwPerfectScrollbarModule,
    ButtonSvgComponent,
    DialogBottomBorderOverlapComponent,
    ButtonComponent,
  ],
})
export class QuestionComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessQuestionService) apiBusinessQuestionService: ApiBusinessQuestionService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(NgZone) ngZone: NgZone;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<QuestionComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    question: Question;
    fromList: boolean;
  };

  TEMPLATES = {
    QUESTION_DETAILS: "question-details",
    QUESTION_AND_ANSWERS: "question-and-answers",
  };
  activeTemplate: string;

  STOCK_VIEW = STOCK_VIEW;
  question: Question;
  isReloadAfterBackToList: boolean;

  ngOnInit() {
    this.question = this.data.question;
    this.changeTemplate(this.TEMPLATES.QUESTION_DETAILS);
  }

  initPhotoSwipe() {
    this.ngZone.runOutsideAngular(() => {
      const lightboxQuestion = new PhotoSwipeLightbox({
        gallery: ".question-image a",
        pswpModule: PhotoSwipe,
      });
      lightboxQuestion.init();
      const lightboxAnswers = new PhotoSwipeLightbox({
        gallery: ".question",
        children: "a",
        pswpModule: PhotoSwipe,
      });
      lightboxAnswers.init();
    });
  }

  changeTemplate(template: string) {
    this.activeTemplate = template;
    this.changeDetectorRef.detectChanges();
    if (this.activeTemplate === this.TEMPLATES.QUESTION_AND_ANSWERS) {
      this.initPhotoSwipe();
    }
  }

  actionAnswer(question, answer: QuestionAnswer) {
    if (this.question.answered_at) {
      return;
    }

    question.selected_answer = answer;
  }

  endQuestion(question: Question) {
    const chosenAnswer = question.answers.find(answer => answer.answer_id === question.selected_answer.answer_id);
    chosenAnswer.is_selected_answer = true;

    this.apiBusinessQuestionService
      .postQuestion({
        playerQuestionId: this.question.player_question_id,
        answers: this.getSelectedAnswers(),
      })
      .subscribe(resp => {
        this.question = resp;
        this.changeTemplate(this.TEMPLATES.QUESTION_DETAILS);
        this.isReloadAfterBackToList = true;
      });
  }

  getSelectedAnswers() {
    return this.question.answers.filter(answer => answer.is_selected_answer).map(answer => answer.answer_id);
  }

  openExplanation() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_DAILY_QUESTIONS.QUESTION_MORE_INFO,
      config: {
        data: this.question.explanation,
      },
    });
  }
  openAnswerExplanation(answer: QuestionAnswer) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_DAILY_QUESTIONS.QUESTION_MORE_INFO,
      config: {
        data: answer.explanation,
      },
    });
  }

  backToList() {
    this.matDialogRef.close(this.isReloadAfterBackToList);
  }
}
