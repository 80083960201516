<div class="global-dialog-core no-transparent">
  <loading></loading>

  <app-dialog-empty-page
    *ngIf="!questions?.length && !customLoading"
    [text]="'daily-questions.no-questions'| myTranslate"></app-dialog-empty-page>

  <div class="button-close-wrapper">
    <app-button-close></app-button-close>
  </div>

  <div class="title-wrapper">
    <app-dialog-title [title]="'daily-questions.daily-questions' | myTranslate"></app-dialog-title>
  </div>

  <div class="container loading-opacity" *ngIf="questions?.length">
    <ow-perfect-scrollbar [scrollGradient]="questions">
      <table>
        <thead>
          <tr>
            <th>{{ 'daily-questions.question' | myTranslate }}</th>
            <th>{{ 'daily-questions.prize' | myTranslate }}</th>
            <th>{{ 'daily-questions.valid-until' | myTranslate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let question of questions">
            <tr
              *ngIf="activeCategory.name ? question.category.name === activeCategory.name : true"
              [class.opacity]="question.is_expired || question.answered_at"
              (click)="openQuestion(question)">
              <td><span [innerHTML]="question.title" [class.span-bold]="question.is_correct === null"></span></td>
              <td>
                <div class="flex-center prizes">
                  <m-ui-currency
                    *ngFor="let currency of question.prizes.currency_prizes"
                    [item]="currency"
                    [lack]="false"
                    [stockView]="STOCK_VIEW.J"></m-ui-currency>

                  <m-ui-product
                    *ngFor="let product of question.prizes.product_prizes"
                    [item]="product"
                    [lack]="false"
                    [stockView]="STOCK_VIEW.J"
                    [lockOpenStorage]="true"></m-ui-product>
                </div>
              </td>

              <td
                [matTooltip]="question.expires_at | date: 'dd.MM.yyyy'"
                [matTooltipPosition]="'above'"
                class="no-wrap">
                {{ question.expires_at | date: "dd.MM.yyyy" }}
              </td>
              <td>
                <i class="svg-icon square-check" *ngIf="question.is_correct !== null && question.is_correct === true"></i>
                <i class="svg-icon square-xmark" *ngIf="question.is_correct !== null && question.is_correct === false"></i>
                <i class="svg-icon square" *ngIf="question.is_correct === null"></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ow-perfect-scrollbar>
  </div>

  <div class="bottom-elements-container" *ngIf="owPaginationDate">
    <app-dialog-bottom-border-overlap>
      <app-button-svg [icon]="'chevron-left'" (clicked)="prev()"></app-button-svg>
      <span class="month">
        <loading [classNames]="['white', 'small']" [customLogicShow]="customLoading"></loading>
        <div *ngIf="!customLoading">
          {{ owPaginationDate.current.month_text_normal }} {{ owPaginationDate.current.year }}
        </div>
      </span>
      <app-button-svg [icon]="'chevron-right'" (clicked)="next()"></app-button-svg>
    </app-dialog-bottom-border-overlap>
  </div>
</div>
