import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-button-svg",
  templateUrl: "button-svg.component.html",
  styleUrl: "button-svg.component.scss",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class ButtonSvgComponent {
  @Input() icon: string = "";
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  click() {
    this.clicked.emit(true);
  }
}
