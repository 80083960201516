import { NgClass } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-dialog-title",
  templateUrl: "dialog-title.component.html",
  styleUrls: ["dialog-title.component.scss"],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class DialogTitleComponent {
  @Input() textStroke: boolean = false;
  @Input() title: string = "Component Title";
}
