import { TREASURES_MAIN_SCENE, TREASURES_SCENE_WORLD_HEIGHT, TREASURES_SCENE_WORLD_WIDTH } from '../treasures.constants';
import { MyScene } from '../../classes/core/MyScene';
import { Subscription } from 'rxjs';
import { MyGame } from '../../classes/core/MyGame';
import { IsoMap } from '../../classes/core/IsoMap';
import { IsoMapLayer } from '../../classes/core/IsoMapLayer';
import { select } from '@ngrx/store';
import { selectGameBoardInitiationData } from '../../../../../store/game/selectors';
import { filter, take, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { Board } from '../../classes/custom/Board.class';
import {BOARD_TILE_HEIGHT, BoardViewMode, GAME_EVENTS, PHASER_CAMERA_ZOOM} from '../../../constants';
import { EVENTS } from '../../../../../core/consts/core/events';
import { TREASURES_SCENE_CONFIG } from '../treasures-scene.config';
import { calculateMinZoomValue } from '../../utils/game.helper';
import { WeatherSystem } from '../classes/WeatherSystem';
import { mobileGui } from '../../../../../core/providers/device.service';
import {gameSetCurrentScene} from '../../../../../store/game/actions';
import {GameFeaturesMap} from '../../../constants/game-features';
import {CenterMap} from '../../interfaces/shared';
import {BASIC_BOOT_SCENE} from '../../scenes-basic/basic.constants';

export class TreasuresMainScene extends MyScene {
  worldData: any;
  weatherSystem: WeatherSystem;
  backgroundImages: Phaser.GameObjects.Image[];

  constructor() {
    // super({
    //   key: TREASURES_MAIN_SCENE
    // });
    super(TREASURES_SCENE_CONFIG);
    this.backgroundImages = [];
    console.log('treasure map triggered');
  }

  waveStart: any;

  private globalEmitterSub: Subscription;
  private boardSubscription: Subscription;

  init(data?) {
    this.passedData = data;
    this.setCurrentScene();
  }

  preload(): void {
    // this.events.on('resize', this.resize, this);
    console.log('treasure map triggered');
    super.preload();
    this.gameService = (this.game as MyGame).gameService;
  }

  create(): void {
    console.log('treasure map triggered');
    const sceneConfig = TREASURES_SCENE_CONFIG;
    this.sceneConfig = sceneConfig;
    this.phaserEvents = new Phaser.Events.EventEmitter();
    this.setCameraControls();
    this.cameras.main.setBackgroundColor("#b7e6eb");
    this.cameras.main.setBounds(
      this.sceneConfig.cameraBounds.x,
      this.sceneConfig.cameraBounds.y,
      this.sceneConfig.cameraBounds.width,
      this.sceneConfig.cameraBounds.height
    );

    if (GameFeaturesMap.sceneBgImages) {
      sceneConfig.backgroundImages.forEach(image => {
        const bgImage = this.add.image(image.x || 0, image.y || 0, image.key);
        bgImage.setDepth(image.z || 0);

        bgImage.setDisplaySize(image.displayWidth + 1, image.displayHeight + 1);

        if (!image.origin) {
          image.origin = {
            x: 0,
            y: 0,
          };
        }
        bgImage.setOrigin(image.origin.x, image.origin.y);

        this.backgroundImages.push(bgImage);
      });
    }

    this.createTiledMap();

    if (!this.buildingsLayer) {
      this.buildingsLayer = new IsoMapLayer({ scene: this, name: "buildingsLayer" });
    }

    this.createBoard();
    // if (GameFeaturesMap.board) {
    //   this.createBoard();
    // } else {
    //   this.gameService.store.dispatch(gameSetCurrentScene({ sceneName: this.sceneConfig.configKeyName }));
    // }

    this.topLayer = new IsoMapLayer({
      scene: this,
      depthIndex: 99,
      name: "top",
    });

    this.globalEmitterSub = this.gameService.globalService.globalEvents
      .pipe(
        tap(console.log),
        filter(event => event.name === EVENTS.GAME.CENTER_TILE)
      )
      .subscribe(event => this.focusOn(event.value));

    if (this.gameService.centerOn) {
      this.focusOn(this.gameService.centerOn);
    }

    this.setCamera();
    this.afterCreate();
    this.gameService.globalService.globalEvents.emit({
      name: "SCENE_CONFIG_CHANGE",
      value: this.sceneConfig,
    });
  }

  createTiledMap() {
    this.isoMap = new IsoMap({
      scene: this,
    });

    if (this.sceneConfig.tiledMapFileName && GameFeaturesMap.tiledMap) {
      this.isoMap.createFromData(`${this.sceneConfig.mapDirectoryName}/${this.sceneConfig.tiledMapFileName}`);
    }
    this.buildingsLayer = this.isoMap.getLayerByName("buildings");

    if (!this.sceneConfig.tiledMapFileName || !this.buildingsLayer) {
      this.buildingsLayer = new IsoMapLayer({ scene: this, name: "buildingsLayer" });
    }

    this.roadsLayer = new IsoMapLayer({ scene: this, name: "roadsLayer" });
    this.roadsLayer.depthIndex = this.buildingsLayer.depthIndex - 1;

    this.topLayer = new IsoMapLayer({
      scene: this,
      depthIndex: 99,
      name: "top",
    });
  }

  focusOn(centerOn: CenterMap) {
    const targetTile = this.board.boardTiles.find(tile => tile.tileData.tile_id === centerOn.tile_id);

    if (!targetTile && this.gameService.lastPlayerIslandId !== centerOn.player_island_id) {
      this.gameService.centerOn = centerOn;
      this.gameService.prepareGameData(this.gameService.playerService.getActivePlayerId(), centerOn.player_island_id);
      this.gameService.globalService.globalEvents.emit({
        name: GAME_EVENTS.START_SCENE,
        value: BASIC_BOOT_SCENE,
        data: {
          sceneConfigKey: centerOn.config_name,
          playerIslandId: centerOn.player_island_id,
        },
      });
      return;
    }

    this.add.tween({
      targets: this.cameras.main,
      duration: 600,
      ease: "Power2",
      scrollX: targetTile.x - this.cameras.main.width / 2,
      scrollY: targetTile.y - this.cameras.main.height / 2 - BOARD_TILE_HEIGHT / 2,
      zoom: 1,
      onUpdate: () => this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom),
    });
    this.gameService.centerOn = null;
  }

  afterCreate() {}

  setCamera() {
    this.cameraStartPos = this.sceneConfig.cameraStartPosition;
    const centerTarget = this.board
      ? this.board.boardTiles.find(boardTile => boardTile.tileData.is_center_camera)
      : null;
    let zoomValue = 0.4;
    if (centerTarget) {
      this.cameraStartPos = {
        x: centerTarget.x,
        y: centerTarget.y,
      };
      zoomValue = 0;
    }
    this.cameras.main.centerOn(this.cameraStartPos.x, this.cameraStartPos.y);
    this.cameras.main.setZoom(Math.max(zoomValue, calculateMinZoomValue(this.cameras.main)));
    setTimeout(() => {
      this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
    }, 50);
  }

  createBoard() {
    debugger;
    this.gameService.store
      .pipe(
        select(selectGameBoardInitiationData),
        filter(data => data.loaded),
        take(1)
      )
      .subscribe(data => {
        const clonedBoard = R.clone(data.boardTiles);
        console.log('treasure main scene');
        this.board = new Board(this, {
          offset: this.sceneConfig.boardStartOffset,
          yAxisSign: this.sceneConfig.yAxisSign,
        });
        this.board.createBoard(
          clonedBoard,
          this.sceneConfig.isOrhto ? BoardViewMode.Ortogonal : BoardViewMode.Isometric
        );
        console.log(this.sceneConfig);
        this.gameService.store.dispatch(gameSetCurrentScene({ sceneName: this.sceneConfig.configKeyName }));
      });
  }

  destroyScene() {
    this.globalEmitterSub.unsubscribe();
    super.destroyScene();
  }

  resize() {
    super.resize();
  }

  // create(): void {
  //   this.phaserEvents = new Phaser.Events.EventEmitter();
  //
  //   const sceneConfig = TREASURES_SCENE_CONFIG;
  //   this.sceneConfig = TREASURES_SCENE_CONFIG;
  //
  //   this.cameras.main.setBackgroundColor(sceneConfig.backgroundColor);
  //   this.cameras.main.setBounds(
  //     -TREASURES_SCENE_WORLD_WIDTH / 2,
  //     0,
  //     TREASURES_SCENE_WORLD_WIDTH,
  //     TREASURES_SCENE_WORLD_HEIGHT
  //   );
  //
  //   sceneConfig.backgroundImages.forEach(image => {
  //     const bgImage = this.add.image(image.x, image.y, image.key);
  //     bgImage.setDisplaySize(image.displayWidth, image.displayHeight);
  //     if (image.origin) {
  //       bgImage.setOrigin(image.origin.x, image.origin.y);
  //     }
  //   });
  //
  //   this.isoMap = new IsoMap({
  //     scene: this
  //   });
  //
  //   this.isoMap.createFromData(this.tiledMapKey);
  //   this.buildingsLayer = this.isoMap.getLayerByName('buildings');
  //
  //   if (!this.buildingsLayer) {
  //     this.buildingsLayer = new IsoMapLayer({scene: this, name: 'buildingsLayer'});
  //   }
  //
  //   this.roadsLayer = new IsoMapLayer({scene: this, name: 'roadsLayer'});
  //   this.roadsLayer.depthIndex = this.buildingsLayer.depthIndex - 1;
  //
  //   this.topLayer = new IsoMapLayer({
  //     scene: this,
  //     depthIndex: 99,
  //     name: 'top'
  //   });
  //
  //   this.cameras.main.roundPixels = true;
  //
  //   debugger;
  //   this.boardSubscription = this.gameService
  //     .store
  //     .pipe(
  //       select(selectGameBoardInitiationData),
  //       filter(state => state.loaded),
  //       take(1)
  //     )
  //     .subscribe((state) => {
  //       const clonedBoard = R.clone(state.boardTiles);
  //       this.board = new Board(this, {offset: sceneConfig.boardStartOffset});
  //       this.board.createBoard(clonedBoard);
  //       this.gameService.store.dispatch(gameSetCurrentScene({sceneName: this.scene.key}));
  //     });
  //
  //   this.gameService.boardService.getWorld(this.gameService.playerService.getActivePlayerId())
  //     .subscribe(world => {
  //       this.worldData = world;
  //       this.gameService.guiService.isSplashShow.next(false);
  //     });
  //
  //   this.setCameraControls();
  //   this.setCamera();
  //
  //   setTimeout(() => {
  //     this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
  //   }, 50);
  //
  //   this.globalEmitterSub = this.gameService.globalService.globalEvents
  //     .pipe(tap(console.log), filter(event => event.name === EVENTS.GAME.CENTER_TILE))
  //     .subscribe(event => this.focusOn(event.value));
  //   // const tempKey = this.input.keyboard.addKey('d');  // Get key object
  //   // tempKey.on('up', () => {
  //   //   this.gameService.guiService.isSplashShow.next(true);
  //   //   this.gameService.game.currentScene.destroyScene();
  //   //   this.gameService.store.dispatch(new ResetBoard());
  //   //
  //   //   this.gameService.prepareGameData(this.gameService.playerService.player.id, null);
  //   //   this.scene.start(DISCOVER_BOOT_SCENE);
  //   // });
  //
  //   this.gameService.boardService.getWorld(this.gameService.playerService.getActivePlayerId())
  //     .subscribe(world => {
  //       this.worldData = world;
  //       this.gameService.guiService.isSplashShow.next(false);
  //     });
  //
  //   const cloudsConfig = [
  //     {
  //       x: -3500,
  //       y: 3600,
  //       z: 1000,
  //     },
  //     {
  //       x: -4000,
  //       y: 6000,
  //       z: 1000
  //     },
  //     {
  //       x: 1000,
  //       y: 5000,
  //       z: 1000
  //     },
  //     {
  //       x: 1500,
  //       y: 9000,
  //       z: 1000
  //     },
  //     {
  //       x: 1000,
  //       y: 10500,
  //       z: 1000
  //     },
  //   ];
  //   if (!mobileGui) {
  //     this.weatherSystem = new WeatherSystem(this, cloudsConfig);
  //   }
  //   this.afterCreate();
  // }

  // setCamera() {
  //   this.cameras.main.centerOn(this.cameraStartPos.x, this.cameraStartPos.y);
  //   this.cameras.main.zoomTo(Math.max(0, calculateMinZoomValue(this.cameras.main)), 0);
  // }
  //
  // afterCreate() {
  //   if (this.gameService.centerOn) {
  //     this.focusOn(this.gameService.centerOn);
  //   }
  // }

  // focusOn(centerOn: CenterMap) {
  //   const targetTile = this.board.boardTiles.find(tile => tile.tileData.tile_id === centerOn.tile_id);
  //   if (!targetTile && this.gameService.lastPlayerIslandId !== centerOn.player_island_id) {
  //     this.gameService.centerOn = centerOn;
  //     this.gameService.prepareGameData(this.gameService.playerService.getActivePlayerId(), centerOn.player_island_id);
  //
  //     let targetSceneKey = CustomMapTypeKeyMap[centerOn.type];
  //     let discoverType;
  //     if (!targetSceneKey) {
  //       targetSceneKey = getTargetSceneKey(centerOn.type);
  //       discoverType = getDiscoverPlaceType({island_group_type: centerOn.type});
  //     }
  //     this.gameService.globalService.globalEvents.emit({
  //       name: GAME_EVENTS.START_SCENE,
  //       value: targetSceneKey,
  //       data: {
  //         playerIslandId: centerOn.player_island_id,
  //         discoverType
  //       }
  //     });
  //     return;
  //   }
  //
  //   this.add.tween({
  //     targets: this.cameras.main,
  //     duration: 600,
  //     ease: 'Power2',
  //     scrollX: targetTile.x - this.cameras.main.width / 2,
  //     scrollY: targetTile.y - this.cameras.main.height / 2 - BOARD_TILE_HEIGHT / 2,
  //     zoom: 1,
  //     onUpdate: () => this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom)
  //   });
  //   this.gameService.centerOn = null;
  // }


  // destroyScene() {
  //   this.globalEmitterSub.unsubscribe();
  //   super.destroyScene();
  // }

}
