import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "../../core/guards/auth.guard";
import { BlockFirstRoutingGuard } from "../../core/guards/block-first-routing.guard";
import { InitGuard } from "../../core/guards/init.guard";
import { SyncGuard } from "../../core/guards/sync.guard";
import { InitialConfigGuard } from "../../guards/initial-config.guard";
import { LandingComponent } from "./base/core/components/landing/landing.component";
import { TempTokenExchangeComponent } from "./base/core/components/temp-token-exchange/temp-token-exchange.component";
import { AUTH_ROUTING_PATH } from "./consts/core/routing-auth.const";
import { AUTH_ROUTING_COMPONENTS } from "./consts/core/routing-auth-components.const";
import { CUSTOM_ROUTING_AUTH_CHILDREN } from "./consts/custom/routing-auth-children.const";
import { CUSTOM_AUTH_ROUTING_COMPONENTS } from "./consts/custom/routing-auth-components.const";
import { CUSTOM_ROUTING_AUTH_PATH } from "./consts/custom/routing-auth-path.const";

const routes: Routes = [
  {
    path: "",
    canActivate: [InitialConfigGuard],
    children: [
      ...CUSTOM_ROUTING_AUTH_PATH,
      {
        path: "",
        canActivate: [SyncGuard],
        data: {
          syncGuards: [InitGuard],
        },
        children: [
          ...CUSTOM_ROUTING_AUTH_CHILDREN,
          {
            path: AUTH_ROUTING_PATH.LOGIN,
            pathMatch: "full",
            component: AUTH_ROUTING_COMPONENTS.LOGIN,
          },
        ],
      },
      {
        path: AUTH_ROUTING_PATH.REGISTRATION,
        component: AUTH_ROUTING_COMPONENTS.REGISTRATION_A,
      },
      {
        path: AUTH_ROUTING_PATH.CHANGE_PASSWORD,
        component: AUTH_ROUTING_COMPONENTS.CHANGE_PASSWORD,
      },
      {
        path: AUTH_ROUTING_PATH.FORGOT_PASSWORD,
        component: AUTH_ROUTING_COMPONENTS.FORGOT_PASSWORD,
      },
      {
        path: AUTH_ROUTING_PATH.PASSWORD_RESET,
        component: AUTH_ROUTING_COMPONENTS.RESET_PASSWORD,
      },
      {
        path: "oauth2/confirm/:token",
        component: CUSTOM_AUTH_ROUTING_COMPONENTS.OAUTH_CONFIRM,
      },
      {
        path: AUTH_ROUTING_PATH.REAUTHORIZE,
        component: AUTH_ROUTING_COMPONENTS.REAUTHORIZE,
        canActivate: [SyncGuard],
        data: {
          syncGuards: [InitGuard, AuthGuard, BlockFirstRoutingGuard],
        },
      },
      {
        path: AUTH_ROUTING_PATH.SOCKET_ERROR_CONNECTION,
        component: AUTH_ROUTING_COMPONENTS.SOCKET_ERROR_CONNECTION,
        canActivate: [SyncGuard],
        data: {
          syncGuards: [InitGuard, BlockFirstRoutingGuard],
        },
      },
      {
        path: AUTH_ROUTING_PATH.AUTHORIZE,
        canActivate: [InitialConfigGuard],
        component: TempTokenExchangeComponent,
      },
      {
        path: AUTH_ROUTING_PATH.LANDING,
        component: LandingComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
