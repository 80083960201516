<!-- LOGO -->
<ng-container *ngIf="!guiService.isSplashShow.value">
  <div class="logo"></div>

  <loading></loading>

  <div class="wrapper">
    <div class="container loading-opacity">
      <form [formGroup]="form" autocomplete="off">
        <!-- LOGIN FORM -->
        <div
          [class.error]="form.get('username').invalid && form.get('username').dirty && form.get('username').errors"
          class="control">
          <div class="label-flex">
            <label>Twój Nick (widoczny będzie w rankingach):</label>
          </div>
          <input
            autocomplete="off"
            class="control-input no-ow-input-directive"
            formControlName="username"
            type="text"/>
        </div>

        <div class="control control-checkbox tc-container">
          <div class="form-checkbox">
            <input autocomplete="off" formControlName="confirm" id="confirm" type="checkbox"/>
            <div class="checkbox-big">
              <div class="checkbox">
                <div class="check"></div>
              </div>
            </div>
          </div>

          <label for="confirm">
            Akceptuję <a [href]="config.hud.player_details.links.terms.url" target="_blank">regulamin</a>
          </label>
        </div>

        <div class="control control-checkbox confirm-rodo-container">
          <div class="form-checkbox">
            <input autocomplete="off" formControlName="confirmRodo" id="confirmRodo" type="checkbox"/>
            <div class="checkbox-big">
              <div class="checkbox">
                <div class="check"></div>
              </div>
            </div>
          </div>

          <label for="confirmRodo">
            Akceptuję <a [href]="config.hud.player_details.links.gdpr.url" target="_blank">klauzulę RODO</a>
          </label>
        </div>

        <div class="buttons flex-center">
          <button (click)="registrationConfirm()" [disabled]="form.invalid" class="base primary">
            Zarejestruj
          </button>
        </div>

      </form>
    </div>
    <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config?.common?.contact_email ?? '' }}</a>
  </div>

  <div class="copyright-container">
    <p [innerHTML]="config.default_page.copyright_text"></p>
  </div>
</ng-container>
