import { NgClass } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-dialog-empty-page",
  templateUrl: "dialog-empty-page.component.html",
  styleUrl: "dialog-empty-page.component.scss",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class DialogEmptyPageComponent {
  @Input() text: string = "";
}
