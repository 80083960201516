<div class="global-dialog-core no-transparent" [ngClass]="activeTemplate">
  <loading></loading>

  <div class="button-close-wrapper">
    <app-button-close></app-button-close>
  </div>

  <div class="button-back-wrapper">
    <app-button-back
      *ngIf="data.fromList && activeTemplate === TEMPLATES.QUESTION_DETAILS"
      [preventDefault]="true"
      (clicked)="backToList()"></app-button-back>
    <app-button-back
      [preventDefault]="true"
      (clicked)="changeTemplate(TEMPLATES.QUESTION_DETAILS)"
      *ngIf="activeTemplate === TEMPLATES.QUESTION_AND_ANSWERS"></app-button-back>
  </div>

  <div class="title-wrapper">
    <app-dialog-title [title]="'daily-questions.daily-questions' | myTranslate"></app-dialog-title>
  </div>

  <ng-container [ngSwitch]="activeTemplate">
    <ng-container *ngSwitchCase="TEMPLATES.QUESTION_DETAILS" [ngTemplateOutlet]="questionDetailsTpl"></ng-container>
    <ng-container
      *ngSwitchCase="TEMPLATES.QUESTION_AND_ANSWERS"
      [ngTemplateOutlet]="questionAndAnswersTpl"></ng-container>
  </ng-container>
</div>

<!-- QUESTION DETAILS TEMPLATE -->
<ng-template #questionDetailsTpl>
  <div class="question-container loading-container center-dialog">
    <ng-container *ngIf="!question.is_expired && !question.answered_at">
      <!-- TEXT -->
      <p class="app-question-title text-1">
        Odpowiedz na pytanie i zdobądź wirtualną walutę niezbędną do rozbudowy Twojego miasta.
      </p>

      <span class="app-question-title text-2">{{ question.title }}</span>

      <!-- TIMER -->
      <div class="timer">Możesz odpowiedzieć do {{ question.expires_at | date: "dd.MM.yyyy" }}</div>
      <div class="separator"></div>
    </ng-container>

    <!-- TEXT -->
    <p class="question-summary" *ngIf="question.answered_at && question.is_correct">
      {{ 'daily-questions.congratulations' | myTranslate }}<br />
      {{ 'daily-questions.correct-answer-bonus' | myTranslate }}<br />
    </p>

    <!-- TEXT -->
    <p class="question-summary" *ngIf="question.answered_at && !question.is_correct">
      {{ 'daily-questions.wrong-answer' | myTranslate }}<br />
      {{ 'daily-questions.no-bonus' | myTranslate }}<br />
    </p>

    <!-- PRIZES -->
    <ng-container *ngIf="!question.answered_at">
      <p class="app-question-title text-3">{{ 'daily-questions.bonus-to-get' | myTranslate }}</p>

      <div class="flex-center prizes">
        <m-ui-currency
          *ngFor="let currency of question.prizes.currency_prizes"
          [item]="currency"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"></m-ui-currency>

        <m-ui-product
          *ngFor="let product of question.prizes.product_prizes"
          [item]="product"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"
          [lockOpenStorage]="true"></m-ui-product>
      </div>
    </ng-container>

    <!-- PLAYER PRIZES -->
    <ng-container *ngIf="question.is_correct">
      <p class="question-prizes-summary">{{ 'daily-questions.bonus-earned' | myTranslate }}</p>

      <div class="flex-center prizes" *ngIf="question.is_correct; else emptyPlayerPrizesTpl">
        <m-ui-currency
          *ngFor="let currency of question.player_prizes.currency_prizes"
          [item]="currency"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"></m-ui-currency>

        <m-ui-product
          *ngFor="let product of question.player_prizes.product_prizes"
          [item]="product"
          [lack]="false"
          [stockView]="STOCK_VIEW.A"
          [lockOpenStorage]="true"></m-ui-product>
      </div>

      <ng-template #emptyPlayerPrizesTpl>
        <p class="app-question-title margin-1">{{ 'daily-questions.empty' | myTranslate }}</p>
      </ng-template>
    </ng-container>
  </div>

  <div class="bottom-elements-container" *ngIf="!question.is_expired || (question.is_expired && question.answered_at)">
    <app-dialog-bottom-border-overlap [elementIsButton]="true">
      <app-button [variant]="'primary'" (clicked)="changeTemplate(TEMPLATES.QUESTION_AND_ANSWERS)">
        {{ question.answered_at ? ('daily-questions.check-answer' | myTranslate) : ('daily-questions.open-question' | myTranslate) }}
      </app-button>
    </app-dialog-bottom-border-overlap >
    <ng-container *ngIf="question.answered_at || question?.show_attachment_before || question?.show_explanation_before">
      <app-dialog-bottom-border-overlap [elementIsButton]="true" *ngIf="question.attachment || question?.show_attachment_before">
        <a [href]="question.attachment" target="_blank">
          <app-button [variant]="'primary'">{{ 'daily-questions.find-out-more' | myTranslate }} </app-button>
        </a>
      </app-dialog-bottom-border-overlap>
      <app-dialog-bottom-border-overlap
        [elementIsButton]="true"
        *ngIf="question.explanation || question?.show_explanation_before">
        <app-button [variant]="'primary'" (clicked)="openExplanation()"> {{ 'daily-questions.find-out-more' | myTranslate }}</app-button>
      </app-dialog-bottom-border-overlap>
    </ng-container>
  </div>
</ng-template>

<!-- QUESTION AND ANSWERS TEMPLATE -->
<ng-template #questionAndAnswersTpl>
  <div class="question-container loading-opacity">
    <!--QUESTION-->
    <div class="question-title">
      <div class="question-image" *ngIf="question.image_url">
        <a [href]="question.image_url" data-pswp-width="1875" data-pswp-height="2500" target="_blank">
          <img [src]="question.image_url" alt="" />
        </a>
      </div>
      <div class="question-texts">
        <p class="title" [innerHTML]="question.title"></p>
        <p
          class="question-attachment"
          *ngIf="question.explanation || question?.show_explanation_before"
          (click)="openExplanation()">
          {{ 'daily-questions.find-out-more' | myTranslate }}
        </p>
      </div>
    </div>
    <div class="separator"></div>
    <div class="answers-container">
      <ow-perfect-scrollbar scrollGradient>
        <!-- ANSWERS -->
        <div class="elem-flex question" *ngFor="let answer of question.answers">
          <button *ngIf="!question.answered_at" class="answer-checkbox" (click)="actionAnswer(question, answer)">
            <i *ngIf="answer.answer_id === question.selected_answer?.answer_id" class="answer-circle"></i>
          </button>
          <button *ngIf="question.answered_at" class="answer-checkbox">
            <img *ngIf="answer.is_correct" [src]="'icon-check.png' | asset: 'ui'" alt="correct" />
            <img *ngIf="!answer.is_correct && answer.is_chosen" [src]="'icon-x.png' | asset: 'ui'" alt="wrong" />
          </button>

          <a
            class="answer-image"
            *ngIf="answer.image_url"
            [href]="answer.image_url"
            data-pswp-width="1875"
            data-pswp-height="2500"
            target="_blank">
            <img [src]="answer.image_url" alt="" />
          </a>

          <!-- ANSWER DESCRIPTION -->
          <div class="answer-texts">
            <p class="answer-description">{{ answer.description }}</p>
            <p class="answer-attachment" *ngIf="answer?.explanation" (click)="openAnswerExplanation(answer)">
              {{ 'daily-questions.find-out-more' | myTranslate }}
            </p>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </div>
  </div>

  <div class="bottom-elements-container" *ngIf="!question.answered_at">
    <app-dialog-bottom-border-overlap [elementIsButton]="true">
      <app-button [variant]="'primary'" (click)="endQuestion(question)" [disabled]="!question.selected_answer">
        {{ 'daily-questions.send-answer' | myTranslate }}
      </app-button>
    </app-dialog-bottom-border-overlap>
  </div>
</ng-template>
