import { NgClass } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-dialog-bottom-border-overlap",
  templateUrl: "dialog-bottom-border-overlap.component.html",
  styleUrl: "dialog-bottom-border-overlap.component.scss",
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass],
})
export class DialogBottomBorderOverlapComponent {
  @Input() elementIsButton: boolean = false;
}
